.section-home {

  display: flex;
  flex-direction: column;
  align-items: center;

  

  &__stages {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 5%;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: $mobile) {
      flex-direction: column;
    }
    
    img {
      width: 550px;
      @media (max-width: $mobile) {
        width: 80%;
      }
    }

    &__infos {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 40px;


      h2 {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: bold;
 
      }

      p {
        font-size: 16px;
        margin-bottom: 20px;

     

        a {
          color: #007bff;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      button.orange-button {
        padding: 10px 20px;
        background: linear-gradient(to bottom, #ff9800, #ff5722);
        border: none;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        color: #ffffff;
        transition: background-color 0.3s;
     

        &:hover {
          background-color: #ff5722;
        }

        span {
          display: inline-block;
        }
      }
    }

    &__img {
      align-self: flex-end;
      margin-left: 20px;
    }
  }

  &__permis {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 5%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: $mobile) {
      flex-direction: column;
    }
    
    img {
      width: 550px;
      
      @media (max-width: $mobile) {
        display: none;
      }
    }

    &__infos {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    

      h2 {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: bold;
        
      }
      i {
        color: $orange;
      }
      p {
        font-size: 16px;
        margin-bottom: 20px;
        text-align: center;
     

        a {
          color: #007bff;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      button.orange-button {
        padding: 10px 20px;
        background: linear-gradient(to bottom, #ff9800, #ff5722);
        border: none;
        border-radius: 4px;
        font-size: 14px;
        cursor: pointer;
        color: #ffffff;
        transition: background-color 0.3s;

        &:hover {
          background-color: #ff5722;
        }

        span {
          display: inline-block;
        }
      }
    }
  }
}
