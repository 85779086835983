.stage-reservation {

    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 40px;
    background-color: whitesmoke;
    padding-left: 150px;
    padding-right: 150px;
    h1 {
      padding: 20px;
    }

    button {
        background-color: $couleur-orange;
        color: white;
        padding: 10px;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        font-size: 16px;
        width: 200px;
        margin-top: 20px;
  
        &:hover {
          background-color: darken($couleur-orange, 10%);
        }
      }

    &__content {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 40px;
        @media (max-width: $mobile) {
          width: 95%;
          flex-direction: column;
          align-items: center;
        }

        &__stage {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: 550px;
            padding: 20px;
            border: 0.5px solid rgb(209, 207, 207);
            background-color: white;
            width: 320px;
            margin-right: 20px;

            @media (max-width: $mobile) {
              margin-right: 0;
            }
            &:hover {
              background: linear-gradient(to right, $blueNight, black);
              color: white;
            }
      
            &__prix {
              p {
                color: $orange;
                font-weight: bold;
                font-size: 30px;
                text-align: center;
                font-family:Verdana, Geneva, Tahoma, sans-serif;
                margin: 0;
              }
            }
            &__infos {
                width: 100%;
                text-align: center;
                img {
                  width: 120px;
                }
      
              .numero {
                font-size: 15px;
                font-family:Verdana, Geneva, Tahoma, sans-serif;
              }
            }
            &__dates {
              width: 100%;
              .ville {
                text-align: center;
                font-weight: bold;
                font-size: 20px;
              }
              .adresse {
                margin-left: 20px;
                text-align: left;
                font-size: 15px;
                font-family:Verdana, Geneva, Tahoma, sans-serif;
              }
              .date {
                margin-left: 20px;
                font-weight: bold;
                font-size: 15px;
              }
              .horaire {
                margin-left: 20px;
                font-size: 15px;
              }
              .place {
                text-align: center;
                color: rgb(40, 234, 33);
                font-weight: bold;
                font-size: 22px;
              }
            }

        }

        &__form {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 565px;
            margin-left: 20px;

            @media (max-width: $mobile) {
              width: 100%;
            }
            &__payment {
              width: 100%;
                height: 450px;
                display: flex;
                flex-direction: column;
                justify-content: center;

            }
        }
        
    }
}

.Map {
    width: 300px;
}