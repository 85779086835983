@charset "UTF-8";
body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

.banner {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background: linear-gradient(to bottom, rgb(8, 0, 108), rgb(241, 113, 8));
  /* padding: 20px; */
  /* padding-bottom: 80px; */
  padding-top: 0px;
  height: 1002px;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 425px) {
  .banner {
    flex-direction: column;
  }
}
.banner__content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 153px;
}
.banner__content__infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 160px;
  line-height: 0%;
  z-index: 1;
}
@media (max-width: 425px) {
  .banner__content__infos {
    margin-right: 0;
  }
}
.banner__content__infos img {
  margin-top: 50px;
  margin-bottom: 26px;
  width: 200px;
}
.banner__content__infos__texte1 {
  color: whitesmoke;
  font-size: 35px;
  font-weight: 600;
}
@media (max-width: 425px) {
  .banner__content__infos__texte1 {
    display: none;
  }
}
.banner__content__infos__texte2 {
  color: whitesmoke;
  font-size: 20px;
  margin-bottom: 56px;
}
@media (max-width: 425px) {
  .banner__content__infos__texte2 {
    font-size: 15px;
  }
}
.banner__content__infos__texte3 {
  color: rgb(255, 118, 6);
  font-size: 15px;
}
.banner__content .Map {
  width: 320px;
}
@media (max-width: 425px) {
  .banner__content .Map {
    width: 180px;
    margin-top: 25px;
  }
}
.banner__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 90px;
}
@media (max-width: 425px) {
  .banner__card {
    flex-direction: column;
  }
}
.banner__card__infos {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}
.banner__card__infos img {
  width: 130px;
}
.banner__card__infos i {
  font-size: 35px;
  margin-bottom: 20px;
  color: #0b009f;
}
.banner__card__infos i:hover {
  color: #f58220;
}
.banner__card__infos h3 {
  font-size: 28px;
  margin: -5px;
  font-weight: bold;
  color: #f5f5f5;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 500px;
  background-color: white;
  border-radius: 5px;
  padding-left: 20px;
  margin: 20px;
}
@media (max-width: 425px) {
  .search-bar {
    width: 90%;
  }
}
.search-bar select {
  flex: 1;
  border: none;
  font-size: 16px;
  padding: 5px;
  margin-right: 10px;
  width: 100%;
}
.search-bar button {
  background-color: #f58220;
  color: white;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.search-bar button:hover {
  background-color: #d8690a;
}
.search-bar button i {
  font-size: 22px;
}

.banner__line {
  position: absolute;
  top: 571px;
  z-index: 0;
  width: 98%;
  height: 46px;
  background-color: black;
  border-radius: 30px;
  border: 1px solid;
  border-color: white;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}
@media (max-width: 425px) {
  .banner__line {
    justify-content: center;
    top: 619px;
  }
}
.banner__line__content {
  width: 50%;
  overflow: hidden;
  background-color: black;
  white-space: nowrap;
  z-index: 0;
}
@media (max-width: 425px) {
  .banner__line__content {
    width: 100%;
  }
}
.banner__line__content p {
  color: white;
  font-size: 17px;
  position: relative;
  animation: mymove 15s infinite;
  animation-timing-function: linear;
}
@keyframes mymove {
  from {
    right: -700px;
  }
  to {
    right: 1500px;
  }
}

.section-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.section-home__stages {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 5%;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 425px) {
  .section-home__stages {
    flex-direction: column;
  }
}
.section-home__stages img {
  width: 550px;
}
@media (max-width: 425px) {
  .section-home__stages img {
    width: 80%;
  }
}
.section-home__stages__infos {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}
.section-home__stages__infos h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}
.section-home__stages__infos p {
  font-size: 16px;
  margin-bottom: 20px;
}
.section-home__stages__infos p a {
  color: #007bff;
  text-decoration: underline;
}
.section-home__stages__infos p a:hover {
  text-decoration: none;
}
.section-home__stages__infos button.orange-button {
  padding: 10px 20px;
  background: linear-gradient(to bottom, #ff9800, #ff5722);
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s;
}
.section-home__stages__infos button.orange-button:hover {
  background-color: #ff5722;
}
.section-home__stages__infos button.orange-button span {
  display: inline-block;
}
.section-home__stages__img {
  align-self: flex-end;
  margin-left: 20px;
}
.section-home__permis {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 5%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media (max-width: 425px) {
  .section-home__permis {
    flex-direction: column;
  }
}
.section-home__permis img {
  width: 550px;
}
@media (max-width: 425px) {
  .section-home__permis img {
    display: none;
  }
}
.section-home__permis__infos {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-home__permis__infos h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}
.section-home__permis__infos i {
  color: #f58220;
}
.section-home__permis__infos p {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: center;
}
.section-home__permis__infos p a {
  color: #007bff;
  text-decoration: underline;
}
.section-home__permis__infos p a:hover {
  text-decoration: none;
}
.section-home__permis__infos button.orange-button {
  padding: 10px 20px;
  background: linear-gradient(to bottom, #ff9800, #ff5722);
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s;
}
.section-home__permis__infos button.orange-button:hover {
  background-color: #ff5722;
}
.section-home__permis__infos button.orange-button span {
  display: inline-block;
}

.slots {
  width: 58%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
  background-color: white;
  margin-left: 70px;
}
@media (max-width: 425px) {
  .slots {
    width: 95%;
    margin-left: 0;
  }
}
.slots__titles {
  display: flex;
  padding: 0px;
  border: 0;
  padding-left: 10px;
  margin-bottom: 5px;
}
.slots__titles__prix {
  width: 15%;
}
.slots__titles__infos {
  width: 45%;
}
.slots__titles__dates {
  width: 25%;
}
.slots__stages {
  padding: 0;
  border: 0;
}
.slots__stages__product {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  border: 0.5px solid rgb(209, 207, 207);
  background-color: whitesmoke;
  height: 100px;
  line-height: 1.5px;
}
@media (max-width: 425px) {
  .slots__stages__product {
    height: 185px;
    line-height: 15.5px;
  }
}
.slots__stages__product:hover {
  background: linear-gradient(to right, #0b009f, black);
  color: white;
}
.slots__stages__product__prix {
  width: 15%;
  text-align: center;
}
.slots__stages__product__prix p {
  color: #f58220;
  font-weight: bold;
  font-size: 25px;
}
@media (max-width: 425px) {
  .slots__stages__product__prix p {
    font-size: 18px;
  }
}
.slots__stages__product__infos {
  width: 40%;
  padding-left: 10px;
  border-left: 1px solid rgb(185, 182, 182);
  margin-top: 5px;
  margin-bottom: 5px;
}
.slots__stages__product__infos .ville {
  font-weight: bold;
  font-size: 20px;
}
@media (max-width: 425px) {
  .slots__stages__product__infos .ville {
    font-size: 15px;
  }
}
.slots__stages__product__infos .adresse {
  font-size: 13px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
@media (max-width: 425px) {
  .slots__stages__product__infos .adresse {
    font-size: 12px;
  }
}
.slots__stages__product__infos .numero {
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.slots__stages__product__dates {
  width: 35%;
  line-height: 16px;
  padding-left: 10px;
}
.slots__stages__product__dates .date {
  font-weight: bold;
  font-size: 14px;
}
@media (max-width: 425px) {
  .slots__stages__product__dates .date {
    font-size: 11px;
  }
}
.slots__stages__product__dates .place {
  color: rgb(40, 234, 33);
  font-weight: bold;
}
@media (max-width: 425px) {
  .slots__stages__product__dates .place {
    font-size: 12px;
  }
}
.slots__stages__product__btn {
  text-align: center;
  width: 15%;
}
@media (max-width: 425px) {
  .slots__stages__product__btn {
    width: 30%;
  }
}
.slots__stages__product__btn button {
  background: linear-gradient(to right, #f58220, #ff6b00);
  color: white;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.slots__stages__product__btn button:hover {
  background: #ff6b00;
}

.stage-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 0.5px solid rgb(209, 207, 207);
  color: grey;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 425px) {
  .stage-form {
    width: 100%;
  }
}
.stage-form h2 {
  color: black;
}
.stage-form__field {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  width: 90%;
}
.stage-form__field label {
  font-size: 14px;
}
.stage-form__field input,
.stage-form__field select {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
  margin-top: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 5px rgb(191, 191, 191);
}
.stage-form__field input:focus,
.stage-form__field select:focus {
  outline: none;
  border-color: #FFA500;
  box-shadow: 0 0 5px rgba(255, 165, 0, 0.5);
}
.stage-form__field--stage {
  flex-direction: initial;
  margin-bottom: 20px;
  align-items: center;
}
.stage-form__field--stage input {
  box-shadow: 0px 0px 0px;
  margin-right: 12px;
}
.stage-form__field__warning-text {
  color: red;
  font-size: 14px;
  width: 90%;
  border: 0.5px solid rgb(209, 207, 207);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}
.stage-form__field-hidden {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
  border: 0.5px solid rgb(209, 207, 207);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding-top: 20px;
}
.stage-form__field-hidden--cas2 {
  padding-bottom: 20px;
}
.stage-form__permis {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 500px;
  border: 0.5px solid rgb(209, 207, 207);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 425px) {
  .stage-form__permis {
    width: 90%;
  }
}
.stage-form__permis img {
  width: 100%;
}
.stage-form__permis__field {
  display: flex;
  flex-direction: column;
  width: 150px;
}
.stage-form__permis__field--newpermis-date-delivrance {
  position: relative;
  bottom: 483px;
  left: 143px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--newpermis-date-delivrance {
    position: initial;
  }
}
.stage-form__permis__field--newpermis-ville {
  position: relative;
  bottom: 524px;
  left: 307px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--newpermis-ville {
    position: initial;
  }
}
.stage-form__permis__field--newpermis-number {
  position: relative;
  bottom: 352px;
  left: 8px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--newpermis-number {
    position: initial;
  }
}
.stage-form__permis__field--newpermis-olddate {
  position: relative;
  bottom: 359px;
  left: 227px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--newpermis-olddate {
    position: initial;
  }
}
.stage-form__permis__field--oldpermis-date-delivrance {
  position: relative;
  bottom: 92px;
  left: 10px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--oldpermis-date-delivrance {
    position: initial;
  }
}
.stage-form__permis__field--oldpermis-ville {
  position: relative;
  bottom: 172px;
  left: 10px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--oldpermis-ville {
    position: initial;
  }
}
.stage-form__permis__field--oldpermis-number {
  position: relative;
  bottom: 131px;
  left: 10px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--oldpermis-number {
    position: initial;
  }
}
.stage-form__permis__field--oldpermis-olddate {
  position: relative;
  bottom: 294px;
  left: 331px;
}
@media (max-width: 425px) {
  .stage-form__permis__field--oldpermis-olddate {
    position: initial;
  }
}
.stage-form__submit {
  margin-top: 20px;
}

header {
  position: fixed;
  z-index: 2;
  /* top: 100px; */
  background-color: black;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: Arial, sans-serif;
  height: 60px;
  background: linear-gradient(to bottom, rgb(0, 0, 0) 1%, rgb(8, 0, 108) 99%);
  width: 100%;
}
@media (max-width: 425px) {
  header {
    padding: 20px;
  }
}
header .logo {
  margin-left: 40px;
}
header .logo img {
  width: 50px;
  max-height: 80px;
}
@media (max-width: 425px) {
  header .logo img {
    width: 40px;
    margin-right: 10px;
  }
}
header .navbar {
  display: flex;
  align-items: center;
}
@media (max-width: 425px) {
  header .navbar {
    display: none;
  }
}
header .navbar__links {
  font-size: 14px;
  /* Taille de police réduite */
  text-decoration: none;
  box-shadow: inset 0 0 0 0 #f58220;
  color: white;
  padding: 20px;
  transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
header .navbar__links:hover {
  color: white;
  box-shadow: inset 300px 0 0 0 #f58220;
}
header .navbar2-icon {
  display: none;
}
header .navbar2-icon i {
  font-size: 20px;
}
@media (max-width: 425px) {
  header .navbar2-icon {
    display: flex;
  }
}
header .navbar2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: absolute;
  right: 0;
  top: 70px;
  z-index: 2;
}
header .login {
  margin-left: 170px;
}
@media (max-width: 425px) {
  header .login {
    display: none;
  }
}
header .login button {
  background: #f58220;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}
header .login button:hover {
  background: #ff6b00;
}
header .infos {
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}
header .infos__phone {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 17px;
  font-weight: bold;
  margin: 0;
  color: #f58220;
}
header .infos__phone i {
  font-size: 25px;
  color: rgb(0, 250, 0);
  margin-right: 5px;
}
@media (max-width: 425px) {
  header .infos__phone {
    font-size: 15px;
  }
}
header .infos__dispo {
  font-size: 12px;
  text-align: center;
  color: white;
}

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 87);
  color: white;
  padding: 50px;
}
@media (max-width: 425px) {
  footer {
    flex-direction: column;
  }
}
footer img {
  width: 100px;
}
footer ul {
  list-style: none;
}
footer ul li {
  color: white;
}

.about {
  width: 50%;
  text-align: center;
}

.stages {
  background-color: whitesmoke;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;
}
.stages .stages__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 65px;
  padding-left: 10px;
  padding-right: 10px;
}
@media (max-width: 425px) {
  .stages .stages__container {
    margin-top: 40px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }
}
.stages .stages__container__route {
  text-align: left;
  font-size: 14px;
  width: 60%;
  padding-left: 90px;
  margin-top: 20px;
}
@media (max-width: 425px) {
  .stages .stages__container__route {
    padding-left: 0;
  }
}
.stages .stages__container h1 {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  width: 100%;
  padding-left: 90px;
  margin-bottom: 0;
  margin-top: 1px;
}
@media (max-width: 425px) {
  .stages .stages__container h1 {
    padding-left: 0;
    text-align: center;
  }
}
.stages .stages__container__text {
  text-align: left;
  font-size: 17px;
  width: 55%;
  padding-left: 90px;
  margin-bottom: 30px;
}
@media (max-width: 425px) {
  .stages .stages__container__text {
    padding-left: 0;
    text-align: center;
    width: 80%;
  }
}
.stages .stages__container__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26%;
  margin-right: 70px;
}
@media (max-width: 425px) {
  .stages .stages__container__info {
    width: 80%;
    margin-right: 0;
    margin-top: 30px;
  }
}
.stages .stages__container__info__contact {
  background-color: white;
  width: 100%;
  height: 230px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.stages .stages__container__info__contact .phone-number {
  font-weight: bold;
  font-size: 27px;
  color: #f58220;
  margin: 10px;
}
.stages .stages__container__info__contact .service {
  font-weight: bold;
  font-size: 22px;
  margin: 1px;
}
.stages .stages__container__info__contact span {
  color: blue;
}
.stages .stages__container__info__pay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 250px;
  background-color: white;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.stages .stages__container__info__pay p {
  margin: 10px;
  font-size: 20px;
}
.stages .stages__container__info__pay .pay-text {
  font-weight: bold;
}
.stages .stages__container__info__pay img {
  width: 60%;
  margin: 10px;
}

.searchbar--stages {
  position: fixed;
  top: 38px;
  left: 10%;
  z-index: 1;
}

.banner__line--stages {
  top: 60px;
  position: fixed;
}

.banner__line__content--stages {
  width: 80%;
}

.stage-reservation {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  background-color: whitesmoke;
  padding-left: 150px;
  padding-right: 150px;
}
.stage-reservation h1 {
  padding: 20px;
}
.stage-reservation button {
  background-color: #FFA500;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 200px;
  margin-top: 20px;
}
.stage-reservation button:hover {
  background-color: #cc8400;
}
.stage-reservation__content {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
}
@media (max-width: 425px) {
  .stage-reservation__content {
    width: 95%;
    flex-direction: column;
    align-items: center;
  }
}
.stage-reservation__content__stage {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 550px;
  padding: 20px;
  border: 0.5px solid rgb(209, 207, 207);
  background-color: white;
  width: 320px;
  margin-right: 20px;
}
@media (max-width: 425px) {
  .stage-reservation__content__stage {
    margin-right: 0;
  }
}
.stage-reservation__content__stage:hover {
  background: linear-gradient(to right, #0b009f, black);
  color: white;
}
.stage-reservation__content__stage__prix p {
  color: #f58220;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
}
.stage-reservation__content__stage__infos {
  width: 100%;
  text-align: center;
}
.stage-reservation__content__stage__infos img {
  width: 120px;
}
.stage-reservation__content__stage__infos .numero {
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.stage-reservation__content__stage__dates {
  width: 100%;
}
.stage-reservation__content__stage__dates .ville {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.stage-reservation__content__stage__dates .adresse {
  margin-left: 20px;
  text-align: left;
  font-size: 15px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.stage-reservation__content__stage__dates .date {
  margin-left: 20px;
  font-weight: bold;
  font-size: 15px;
}
.stage-reservation__content__stage__dates .horaire {
  margin-left: 20px;
  font-size: 15px;
}
.stage-reservation__content__stage__dates .place {
  text-align: center;
  color: rgb(40, 234, 33);
  font-weight: bold;
  font-size: 22px;
}
.stage-reservation__content__form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 565px;
  margin-left: 20px;
}
@media (max-width: 425px) {
  .stage-reservation__content__form {
    width: 100%;
  }
}
.stage-reservation__content__form__payment {
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Map {
  width: 300px;
}

