.banner {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background: linear-gradient(to bottom, rgb(8, 0, 108), rgb(241, 113, 8));
  /* padding: 20px; */
  /* padding-bottom: 80px; */
  padding-top: 0px;
  height: 1002px;
  width: 100%;
  flex-direction: column;

  @media (max-width: $mobile) {
    flex-direction: column;
  }






  
  &__content {


    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 153px;



    &__infos {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 160px;
      line-height: 0%;
      z-index: 1;
      @media (max-width: $mobile) {
        margin-right: 0;
      }
      img {
        margin-top: 50px;
        margin-bottom: 26px;
        width: 200px;
      
      }
      
      &__texte1 {
        color: whitesmoke;
        font-size: 35px;
        font-weight: 600;
        @media (max-width: $mobile) {
          display: none;
        }


      }

      &__texte2 {
        color: whitesmoke;
        font-size: 20px;
        margin-bottom: 56px;
        @media (max-width: $mobile) {
          font-size: 15px;
        }

      }

      &__texte3 {
        color: rgb(255, 118, 6);
        font-size: 15px;

      }

    }

    .Map {
      width: 320px;
      @media (max-width: $mobile) {
        width: 180px;
        margin-top: 25px;
      }
  }
  


}









&__card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-top: 90px;



  @media (max-width: $mobile) {
    flex-direction: column;
  }


  &__infos {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;

    img {
      width: 130px;
    }
    i {
      font-size: 35px;
      margin-bottom: 20px;
      color: $blueNight;

      &:hover {
        color: $orange;
      }
    }

    h3 {
      font-size: 28px;
      margin: -5px;
      font-weight: bold;
      color: #f5f5f5;
    }


  }
}
}









.search-bar {
  display: flex;
  align-items: center;
  width: 500px;
  background-color: white;
  border-radius: 5px;
  padding-left: 20px;
  margin: 20px;


  @media (max-width: $mobile) {
    width: 90%;
  }


  select {
    flex: 1;
    border: none;
    font-size: 16px;
    padding: 5px;
    margin-right: 10px;
    width: 100%;
  }

  button {
    background-color: $orange;
    color: white;
    border: none;
    height: 50px;
    width: 50px;
    border-radius: 5px;


    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($orange, 10%);
    }
    i {
      font-size: 22px;
    }
  }
}












.banner__line {
  position: absolute;
  top: 571px;
  z-index: 0;
  width: 98%;
  height: 46px;
  background-color: black;
  border-radius: 30px;
  border: 1px solid;
  border-color: white;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  @media (max-width: $mobile) {
    justify-content: center;
    top: 619px;
  }

  &__content {
    width: 50%;
    overflow: hidden;
    background-color: black;
    white-space: nowrap;
    z-index: 0;

    @media (max-width: $mobile) {
      width: 100%;
    }

    p{
      color: white;
      font-size:17px;
      position: relative;
      animation: mymove 15s infinite;
      animation-timing-function: linear;
        @keyframes mymove{
          from {
          right: -700px;
          }
          to{
          right: 1500px;
          }
        }
    }
  }
    
    
}