$couleurBleuNuit: #181830;
$couleurOrange: #FFA500;

.stages {
  background-color: whitesmoke;
  width: 100%;
  padding-bottom: 50px;
  padding-top: 50px;


  .stages__container {
    display: flex;
    flex-wrap: wrap;

    justify-content: space-between;
    align-items:flex-start;
    padding-top: 65px;
    padding-left: 10px;
    padding-right: 10px;

    @media (max-width: $mobile) {
      margin-top: 40px;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
    }
    &__route {
      text-align: left;
      font-size: 14px;
      width: 60%;
      padding-left: 90px;
      margin-top: 20px;
      @media (max-width: $mobile) {
        padding-left: 0;
      }
      
    }
    h1 {
        text-align: left;
        font-size: 28px;
        font-weight: bold;
        width: 100%;
        padding-left: 90px;
        margin-bottom: 0;
        margin-top: 1px;
        @media (max-width: $mobile) {
          padding-left: 0;
          text-align: center;
        }
      }
      &__text {
        text-align: left;
        font-size: 17px;
        width: 55%;
        padding-left: 90px;
        margin-bottom: 30px;
        @media (max-width: $mobile) {
          padding-left: 0;
          text-align: center;
          width: 80%;
        }

      }
















    &__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 26%;
      margin-right: 70px;
   
      @media (max-width: $mobile) {
        width: 80%;
        margin-right: 0;
        margin-top: 30px;
      }

      &__contact {
        background-color: white;
        width: 100%;
        height: 230px;
        margin-bottom: 20px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        text-align: center;
        
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    
        
        .phone-number {
          font-weight: bold;
          font-size: 27px;
          color: $orange;

          margin: 10px;
        }
        .service {
          font-weight: bold;
          font-size: 22px;
          margin: 1px;
        }
        span {
          color: blue;
        }
      }

      &__pay {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 250px;
        background-color: white;
        justify-content: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        p {
          margin: 10px;
          font-size: 20px;
        }

        .pay-text {
          font-weight: bold;
        }

        img {
          width: 60%;
          margin: 10px;
        }
      }
    }
  }
}


.searchbar--stages {
  position: fixed;
  top: 38px;
  left: 10%;
  z-index: 1;
}

.banner__line--stages {
  top: 60px;
  position: fixed;
}

.banner__line__content--stages {
  width: 80%;

}