
footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(0, 0, 87);
    color: white;
    padding: 50px;

    
    @media (max-width: $mobile) {
        flex-direction: column;
    }
    img {
        width: 100px;
    }
    ul {
        list-style: none;
        li { 
            color: white;
        }

    }


}
