
header {
  position: fixed;
  z-index: 2;
  /* top: 100px; */
  background-color: black;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-family: Arial, sans-serif;
  height: 60px;
  background: linear-gradient(to bottom, rgb(0, 0, 0) 1%, rgb(8, 0, 108) 99%);
  width: 100%;


  @media (max-width: $mobile) {
    padding: 20px;
  }
  

  .logo {
    margin-left: 40px;
    img {
      width: 50px;
      max-height: 80px;
      @media (max-width: $mobile) {
        width: 40px;
        margin-right: 10px;
      }
    
    }
  }



  .navbar {
    display: flex;
    align-items: center;
    @media (max-width: $mobile) {
      display: none;
    }

    &__links {

      font-size: 14px; /* Taille de police réduite */
      text-decoration: none;

      box-shadow: inset 0 0 0 0 $orange;
      color: white;
      padding: 20px;

      transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
        &:hover {
          color: white;
          box-shadow: inset 300px 0 0 0 $orange;
        }
    }
    
  }






  .navbar2-icon {
    i {
      font-size: 20px;
    }
    display: none;
    @media (max-width: $mobile) {
      display: flex;
    }
  }




  .navbar2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 2;




  }










  .login {
    margin-left: 170px;
    @media (max-width: $mobile) {
      display: none;
    }
    button {
      background: $orange;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background: #ff6b00;
      }
    }
  }
  .infos {
    line-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 40px;

    &__phone {
      i {
        font-size: 25px;
        color: rgb(0, 250, 0);
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
      margin: 0;
      color: $orange;
      @media (max-width: $mobile) {
        font-size: 15px;
      }
      
    }
    &__dispo {
      font-size: 12px;
      text-align: center;
      color: white;
  
      
    }
  }
}




	


